




















import Vue, { PropType } from 'vue'
import { IMachine } from '@/types/machines'
import apiClient from '@/services/danaApi'
import AuthService from '@/services/authService'
import { IUserData } from '@/types/user'

//TODO remove unused also in template
export default Vue.extend({
	name: 'ExtendLicenceDialog',
	props: {
		value: {
			type: Boolean,
			default: () => false,
		},
		machineDetail: {
			type: Object as PropType<IMachine>,
			required: true,
		},
	},
	computed: {
		isLicence(): boolean {
			return !!this.machineDetail.board.mind.licences?.datetime_to
		},
		userData(): IUserData {
			return AuthService.getUserData()
		},
	},
	methods: {
		submit(userData: IUserData, machineDetail: IMachine) {
			console.log(machineDetail.board.mind.licences?.datetime_to ?? null)
			apiClient
				.post('/management/request_licence', {
					email: userData.email,
					sn: machineDetail.sign.sn.sn,
					mac: machineDetail.board.mac.mac,
					room: machineDetail.location.name,
					expiration_date: machineDetail.board.mind.licences?.datetime_to,
				})
				.then(() => {
					this.$store.dispatch('snackbar/setSnackbar', {
						message: 'Request has been successfully send',
						color: 'success',
					})
				})
				.catch((error) => {
					console.error(error.response)
					if (error.response.status === 422) {
						this.$store.dispatch('snackbar/setSnackbar', {
							message: `Error: ${[error.response.data.detail[0].loc[1]]} ${[error.response.data.detail[0].msg]}`,
							color: 'error',
						})
					} else if (error.response.status === 400) {
						this.$store.dispatch('snackbar/setSnackbar', {
							message: error.response.data.detail,
							color: 'error',
						})
					}
				})
		},
	},
})
