



























import Vue from 'vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import apiClient from '@/services/danaApi'
import { IDataTable, IDataTableHeader, IFilter } from '@/types/table'
import { ILocation, IMachine, IMachineQuery } from '@/types/machines'
import { IChoice } from '@/types/select'
import qs from 'qs'
import ExtendLicenceDialog from '@/components/Dialogs/ExtendLicenceDialog.vue'
import moment from 'moment-timezone'

export default Vue.extend({
	name: 'Machines',
	components: {
		ExtendLicenceDialog,
		DataTable,
	},
	data() {
		return {
			tableProps: {
				itemsKey: '',
				sort: false,
				sortBy: [''],
				actions: [
					{
						name: 'actionDetail',
						icon: {
							icon: 'fa-info-circle',
							size: '16',
						},
						tooltip: 'detail',
						color: 'blue',
					},
					{
						name: 'actionExtendLicence',
						icon: {
							icon: 'fa-award',
							size: '16',
						},
						tooltip: 'Extend Licence',
						color: 'blue',
					},
				],
			} as IDataTable,
			machines: [] as Array<IMachine>,
			machinesCount: 0,
			machinesLoading: false,
			locations: [] as Array<IChoice>,
			dialog: false,
			licenceDialog: {} as IMachine,
		}
	},
	computed: {
		headers() {
			const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

			//ten minutes offset
			const lastOnlineSuccess = moment(new Date(new Date(date).setMinutes(date.getMinutes() - 10)).toISOString())
				.format()
				.substr(0, 19)

			//one hour offset
			const lastOnlineWarning = moment(new Date(new Date(date).setHours(date.getHours() - 1)).toISOString())
				.format()
				.substr(0, 19)

			//one month offset
			const licenseSuccess = moment(new Date(new Date(date).setMonth(date.getMonth() + 1)).toISOString())
				.format()
				.substr(0, 19)

			//less than month to today
			const licenseWarning = moment(new Date(date)).format().substr(0, 19)

			return [
				{
					text: 'SN',
					value: 'sign.sn.sn',
				},
				{
					text: 'Mac',
					value: 'board.mac.mac',
				},
				{
					text: 'Game System',
					value: 'board.mind.multigame.name',
				},
				{
					text: 'Room',
					value: 'location.name',
				},
				{
					text: 'License',
					value: 'board.mind.licences.datetime_to',
					type: 'dateTime',
					colorSwatch: {
						success: licenseSuccess,
						warning: licenseWarning,
					},
				},
				{
					text: 'Last Online',
					value: 'board.mind.last_online',
					type: 'dateTime',
					colorSwatch: {
						success: lastOnlineSuccess,
						warning: lastOnlineWarning,
						featureLock: true,
					},
				},
			] as Array<IDataTableHeader>
		},
		filters(): Array<IFilter> {
			return [
				{
					source: 'locations',
					type: 'SelectInput',
					label: 'Room Filter',
					attributes: {
						multiple: true,
					},
					choices: this.locations,
				},
			]
		},
	},
	methods: {
		async getMachines(options?: IMachineQuery) {
			this.machinesLoading = true

			const offset = (): number | undefined => {
				if (options?.pagination?.page && options?.pagination?.itemsPerPage) {
					return (options.pagination.page - 1) * options.pagination.itemsPerPage
				} else {
					return undefined
				}
			}

			const rawParams = qs.stringify(
				{
					limit: options?.pagination?.itemsPerPage,
					offset: offset(),
					location_id: options?.filters?.locations,
				},
				{
					arrayFormat: 'repeat',
					indices: false,
				},
			)

			const params = new URLSearchParams(rawParams)

			await apiClient
				.get('/info/machines', {
					params,
				})
				.then((response) => {
					this.machinesLoading = false
					this.machines = response.data.result.machines
					this.machinesCount = response.data.result.count
				})
		},
		async getLocations() {
			await apiClient.get('/info/locations').then((response) => {
				this.locations = response.data.result.map(
					(value: ILocation): IChoice => ({
						value: value.id,
						text: value.name,
					}),
				)
			})
		},
		actionDetail(event: IMachine): void {
			this.$router.push({
				path: `/machines/detail/${event.board.mind.id}`,
			})
		},
		actionExtendLicence(event: IMachine): void {
			this.licenceDialog = event
			this.dialog = true
		},
		fetchMachines(event: any) {
			this.getLocations()
			this.getMachines(event)
		},
	},
})
